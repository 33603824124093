.landing-page {
	max-height: 100vh;
	font-family: "Poppins", sans-serif;
	z-index: 0;
	background-color: #efefef;
	// background-image: radial-gradient(
	// 	circle 827px at 47.3% 48%,
	// 	rgba(255, 255, 255, 1) 0%,
	// 	rgb(228, 225, 225) 90%
	// );
	margin: 0 auto;
	max-width: 100%;
}

@media screen and (min-width: 420px) {
	.landing-page {
		max-width: 400px;
		margin: 5% auto;
		max-height: 80vh;
	}
}

.lp-container {
	margin: 3% auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.lp-heading {
	font-size: 1.7rem;
	width: 90%;
	z-index: 10;
	color: #fff;
	padding: 10px;
	word-wrap: break-word;
	line-height: 1.5;
	background: linear-gradient(rgb(13, 48, 112) 0%, #0a0ef3 80%);
	border-radius: 8px;
}
.lp-heading-2 {
	font-size: 1rem;
	width: 90%;
	font-weight: 600;
	color: #01010a;
	background-color: #f60;
	border-top-left-radius: 37px 140px;
	border-top-right-radius: 23px 130px;
	border-bottom-left-radius: 110px 19px;
	border-bottom-right-radius: 120px 24px;
	margin: 0 auto;
	padding: 7px;
}

.lp-description {
	font-size: 14.5px;
	color: #0a0ef3;
	width: 90%;
	margin: 5px auto;
	line-height: 2;
	white-space: pre-line;
	letter-spacing: 1px;
}

.email-highlight {
	font-weight: 600;
	color: #000;
}

.store-contact {
	width: 90%;
	height: min-content;
	margin: 5% 0;
}

.lp-button {
	margin: 5%;
}

.textloop {
	color: #f60;
	font-weight: 600;
}

.lp-auth-btns {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 0 auto;
}
