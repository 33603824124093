.footer-container {
	min-width: 100%;
	background-color: #efefef;
	color: rgb(26, 26, 97);
	margin: 10% auto 0 auto;
	padding: 1.5%;
}

.ft-copyright {
	font-size: 12px;
	font-weight: 600;
	margin: 5px auto;
	width: max-content;
}
