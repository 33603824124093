//  variables

$primaryColor: #efefef;
$accentColor: #f66b0e;
$fontColor: #112b3c;
$secondaryColor: #f8efd4;
//****************************
@media screen and (min-width: 420px) {
	.home-wrapper {
		width: 100%;
		margin: 0;
		padding: 0;
		min-height: 100vh;
		overflow-y: hidden;
		background-color: #112b3c;
		// border: 5px solid #f60;
	}

	.signup-page {
		max-width: 400px;
		margin: 5% auto;
		max-height: 80vh;
		overflow-y: scroll;
		border-radius: 4%;
	}

	body {
		background-color: #2a2a2a;
	}
}

.home-container {
	display: grid;
	grid-template-rows: 0.75fr 1fr;
	align-items: center;
	font-family: "Poppins", sans-serif;
	height: 90vh;
	text-align: center;
	margin: 0 auto;
	width: 100%;
}

.thank-you {
	font-size: 18px;
	margin: 0 auto;
	text-align: center;
}

.home-advertise {
	height: 100%;
	width: 90%;
	text-align: center;
	margin: 0 auto;
}

.home-advertise-image {
	height: 20rem;
	width: 20rem;
}

.fa-circle-check {
	color: #95cd41;
	font-size: 10rem;
	margin: 15px auto;
}

// admin home page style

.admin-buttons {
	margin: 5px auto;
}

// client home page style

.client-container {
	padding-top: 3%;
	text-align: center;
	background-color: #fff;
}

.search-bar {
	width: 90%;
	margin: 2% auto;
	font-size: 1.2rem;
	padding: 15px;
	border: none;
	border-bottom: 1px solid $accentColor;
	background-color: $primaryColor;
	color: $fontColor;
	height: 5%;
	font-family: "Poppins", sans-serif;
}

.disclaimer {
	font-size: 11px;
	color: rgb(211, 66, 66) !important;
	width: 90%;
	margin: 2% auto;
	letter-spacing: 0.5px;
	text-align: left;
	padding: 3px;
	background-color: #efefef;
	line-height: 1.5;
}

.ads-home {
	height: 90%;
	width: 90%;
	margin: 0 auto;
}

.card-container {
	margin: 10px auto;
	border: 1px solid $fontColor;
	background-color: none;
}

.card-header {
	font-size: 14px;
	font-weight: 600;
	width: 100%;
	letter-spacing: 0.5px;
	color: $secondaryColor;
	background-color: $fontColor;
	border-radius: 25px 0 0 25px;
	border-left: 2px solid $fontColor;
	border-top: 2px solid $fontColor;
	border-bottom: 2px solid $fontColor;
	box-shadow: 5px 5px 2.5px 0px rgba(0, 0, 0, 0.56);
}

.card-footer {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

// .card-item {
//   display: grid;
//   grid-template-columns: 0.75fr 1fr;
//   align-items: center;
//   font-size: 12px;

// }

.card-img {
	height: 100px;
	width: 100px;
	margin: auto;
}

.css-1tb0nc5-MuiTable-root {
	width: 100% !important;
}

.store-container {
	display: grid;
	grid-template-columns: 1fr 0.33fr 0.33fr 0.33fr;
	justify-content: flex-start;
	gap: 0.5rem;
	align-items: center;
}

.store-name {
	width: max-content;
	font-size: 13px;
	font-weight: 550;
	border-bottom: 1px dotted #555;
}

.store-link {
	text-decoration: dotted;
	color: $fontColor;
	font-weight: 600;
}

.store-link:visited {
	color: $fontColor;
}

.store-price {
	font-size: 13px;
	width: max-content;
	font-weight: 600;
	color: $fontColor;
}

.fa-thumbs-up {
	color: #6bcb77;
	border: 0.5px solid #6bcb77;
	font-size: 12px;
	background: none;
	border-radius: 25px;
	padding: 5px;
}

.fa-thumbs-down {
	color: #ff6b6b;
	border: 0.5px solid #ff6b6b;
	font-size: 12px;
	background: none;
	border-radius: 25px;
	padding: 5px;
}

.thumbs-up-down {
	background: none;
	border: none;
	padding: 0;
}

.action-btn {
	width: max-content;
	color: $fontColor !important;
	border: 1px solid $fontColor !important;
	font-weight: 500;
	font-size: 1rem;
}

.item-row {
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-column-gap: 6px;

	align-items: center;
	padding: 3px 0;
	width: 100%;
	text-align: left;
}

.item-container {
	display: flex;
	flex-direction: column;
	border: 1px dotted #aeafad;
	padding: 5px 2px;
	width: 100%;
}

// .item-row>td {
//   margin-left: 5px;
// }

.meter {
	width: 60%;
	background-color: #68bb5d;
	border-radius: 10px;
	height: 4px;
}

.meter > span {
	display: block;
	height: 100%;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	background-color: #ff6b6b;
}

.meter-container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 5px 0;
	position: relative;
	min-height: 40px;
}

.last-update {
	text-transform: lowercase;

	color: rgb(44, 44, 44) !important;
	font-weight: 400;
	position: absolute;
	bottom: -3px;
	left: 25px;
	font-size: 8px;
	word-wrap: break-word;
}

.total-votes {
	text-transform: lowercase;
	color: rgb(44, 44, 44) !important;
	font-weight: 400;
	position: absolute;
	top: 1px;
	left: 60px;
	font-size: 9px;
}

.grocery-list-switch {
	color: $accentColor;
	font-size: 14px;
	width: fit-content;
	margin: 5px auto;
}

.form-check-input:checked {
	background-color: $accentColor;
	border-color: $accentColor;
}

.price-upload-or {
	font-size: 2rem;
	font-weight: 800;
	text-decoration: underline;
}
