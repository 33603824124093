.signup-page {
	min-height: 90vh;
	font-family: "Poppins", sans-serif;
	z-index: -10;
	background-color: #efefef;
}

.signup-heading {
	margin: 0 auto;
	width: max-content;
	padding: 10% 0 2% 0;
	border-bottom: 0.5px solid #f60;
	font-size: 2rem;
}

.form-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 10% 10% 3% 10%;

	text-align: center;
}

.avatar-dropdown {
	margin: 0 auto;
}

.avatar-selector {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 90%;
	background-color: #e9e9e9;
}

.input-text {
	font-family: "Poppins", sans-serif !important;
	font-size: 18px;
	color: #f60 !important;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
	color: #f60;
}

.signup-btn,
.btn-primary {
	width: max-content;
	color: #f60 !important;
	border: 1px solid #f60 !important;
	margin: 3% auto;
	font-weight: 600;
}

.login-line {
	font-size: 12px;
	margin: 0 auto;
	width: max-content;
	padding: 0;
}

.checkbox-text {
	font-size: 12px;
	margin: 8px auto;
	text-align: left;
}

.ads-message {
	font-size: 12px;
	margin: 0 auto;
	width: max-content;
	padding: 0;
	color: #f60;
	font-weight: 600;
}

.ads-container {
	height: 15rem;
	width: 90%;
	position: relative;
	margin: 5% auto;
	text-align: center;
	border-top: 1px solid #e9e9e9;
	box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
		6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
		12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
		22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
		41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
		100px 100px 80px rgba(0, 0, 0, 0.07);
}

.ads-signup {
	height: 100%;
	width: 100%;
	margin: 0 auto;
}

.btn-secondary {
	background-color: #7a7079;
}

@media screen and (min-width: 420px) {
	.signup-page {
		max-width: 400px;
		margin: 5% auto;
		max-height: 80vh;
		// border: 5px solid #f60;
	}
}
