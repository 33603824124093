//  variables

$primaryColor: #efefef;
$accentColor: #f66b0e;
$fontColor: #205375;
$logoFontColor: #112b3c;

.nav-container {
	display: grid !important;
	grid-template-columns: 3fr 1fr 1fr !important;
	justify-content: space-around !important;
	align-items: center !important;
}

.login-nav-container {
	display: grid !important;
	grid-template-columns: 3fr 2.5fr 2.5fr !important;
	justify-content: space-around !important;
	align-items: center !important;
	max-width: 100vw !important;
}

.company-logo {
	height: 3rem;
	width: 2.7rem;
	margin-right: 0.5rem;
}

p {
	margin: 0;
}

.btn-primary:disabled {
	background-color: transparent !important;
}

.company-name {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	color: $logoFontColor !important;
	font-size: 1.3rem;
}

.fav-btn {
	padding: 0;
	border: none !important;
}

.avatar-name {
	display: grid;
	align-items: center;
}

.css-i4bv87-MuiSvgIcon-root {
	font-size: 36px !important;
}

.css-106c1u2-MuiBadge-badge {
	background-color: $fontColor !important;
	top: 2px !important;
	right: 2px !important;
}

.nav-links {
	display: grid;
	grid-template-columns: 1fr 1fr;
	text-align: center;
}

.login-signup {
	font-size: 16px;
}

.sign-up {
	color: $accentColor !important;
	text-align: center;
}

.avatar {
	background-color: #81f5ff;
}

.signup-avatar {
	height: 2.5rem;
	width: 2.5rem;
	background-color: #81f5ff;
	border-radius: 25px;
	color: #7a7079;
}

.user-name {
	font-size: 13px;
	font-family: "Poppins", sans-serif;
}

.bg-dark {
	background-color: $primaryColor !important;
}

.btn-primary {
	background-color: transparent;
	border: none;
	color: #47d0bd;
}

.btn-primary:hover {
	background-color: #9df0ac;
	color: #240621;
}

.fa-solid {
	font-size: 1.5rem;
	color: $accentColor;
}
